import React, { Fragment, Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { push } from "connected-react-router";

import saripayLogo from "../../../assests/icons/saripay-logo-black.svg";
import closeButton from "../../../v5-icons/close-thin.svg";
import chatSupport from "../../../v5-icons/chat-support.svg";

import JobDetailSection from "./JobDetailSection/JobDetailSection";
import Moment from "../../moment/moment";
import ThankUsing from "./ThankUsing/ThankUsing";
import JobReceiptCost from "./JobReceiptCost/JobReceiptCost";
import TitleBar from "../../../organisms/title-bar";
import JobReceiptPaymentMethod from "./JobReceiptPaymentMethod/JobReceiptPaymentMethod";
import JobReceiptRow from "./JobReceiptRow/JobReceiptRow";
import DynamicIcon from "../../ui-elements/DynamicIcon/DynamicIcon";

import { fetchJob, checkPaymentMethodUsed } from "../../../utils/redux/actions/jobsActions";
import { setDefaultPaymentDetails, setDefaultCardDetail, fetchJobAndPaymentLoading } from "../../../utils/redux/actions/jobsActions/jobsChangeState";
import { appGoHome } from "../../../utils/redux/actions/commonActions";
import { navigateToSupport } from "../../../utils/redux/actions/supportActions/supportChangeState";
class JobReceipt extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isToggle: false,
		};

		this.onBack = this.onBack.bind(this);
		this.toggleDetail = this.toggleDetail.bind(this);
	}

	componentDidMount() {
		if (_.isEmpty(this.props.job))  {
			this.props.dispatch(fetchJobAndPaymentLoading(true));
			this.props.dispatch(fetchJob(this.props.match.params.id))
			.then(() => {
					this.props.dispatch(checkPaymentMethodUsed());
					this.props.dispatch(fetchJobAndPaymentLoading(false));
				})
		} else {
			this.props.dispatch(checkPaymentMethodUsed());
		}
	}

	onBack() {
		this.props.history.goBack();
	}

	toggleDetail(e) {
		this.setState({ isToggle: !this.state.isToggle });
	}

	onSupport = () => {
		this.props.dispatch(push("/support"));
		this.props.dispatch(navigateToSupport());
	};

	render() {
		return (
			<Fragment>
				{window.location.pathname.indexOf("receipt") > -1 && !_.isEmpty(this.props.job) ? (
					<div className="job-receipt-home">
						<TitleBar
							rightIcon={chatSupport}
							leftIcon={closeButton}
							handleClickLeftIcon={() => {
								this.props.dispatch(appGoHome());
								this.props.dispatch(setDefaultPaymentDetails());
								this.props.dispatch(setDefaultCardDetail());
							}}
							handleClickRightIcon={this.onSupport}
							lozengeObject={{ title: "COMPLETED", color: "charcoal", heading: this.props.job.product.commercialName }}
						/>
					</div>
				) : null}

				<div className={`job-receipt-box ${window.location.pathname.indexOf("receipt") > -1 ? "Has_Chat_Bar" : ""}`}>
					<div className="job-receipt m-t-5">
						<div className="first-section">
							<div className="job-receipt-header">
								<div className="job-receipt-logo">
									<img className="job-my-kuya-logo" src={saripayLogo} alt="mykuya-logo" />
								</div>
								<div className="job-receipt-date">
									<h5>
										<Moment format="DD MMM YYYY">{this.props.job.dateTime}</Moment>
									</h5>
								</div>
							</div>
							<JobReceiptCost loading={this.props.fetchJobAndPaymentLoading} job={this.props.job} client={this.props.common.client} />
							{!_.isEmpty(this.props.job) && !_.isNull(this.props.paymentDetails) && (
								<JobReceiptPaymentMethod
									jobCost={this.props.job.jobCost}
									paymentDetails={this.props.paymentDetails}
									cardDetail={this.props.cardDetail}
								/>
							)}
						</div>
						<div className="second-section">
							{!_.isEmpty(this.props.job) && (
								<JobReceiptRow classname="service-name" value={this.props.job.product.commercialName} />
							)}
							<h5 className={` detail-toggle ${!this.state.isToggle ? "hide" : ""}`} onClick={this.toggleDetail}>
								Hide job details <DynamicIcon icon="Up" classes="" />
							</h5>
							<h5 className={` detail-toggle ${this.state.isToggle ? "hide" : ""}`} onClick={this.toggleDetail}>
								Show job details <DynamicIcon icon="Down" classes="" />
							</h5>
						</div>
						{this.state.isToggle
							? this.props.job &&
							  !_.isNull(this.props.job) && (
									<JobDetailSection
										client={this.props.common.client}
										job={!_.isNull(this.props.unrated) ? this.props.unrated : this.props.job}
									/>
							  )
							: null}
						<div className="third-section">
							<ThankUsing />
						</div>
					</div>
				</div>
				{
					<div className="request-again-box">
						<Button onClick={this.onSupport} type="button" className="Job_Receipt_Help_Btn">
							<span className="chat_Support_Receipt">
								<img src={chatSupport} alt="" />
							</span>
							<span>Get Help Now</span>
						</Button>
					</div>
				}
			</Fragment>
		);
	}
}


const mapStateToProps = state => ({
	...state.jobs,
	common: state.common
});

export default connect(mapStateToProps)(JobReceipt);