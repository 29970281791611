import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import Timer from "easytimer.js";
import $ from "jquery";
import { parse } from "query-string";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { intervalJobStateChecker, jobChatHistoryWillUnMount, fetchActiveJob } from "../../../utils/redux/actions/jobsActions";
import { clearTrackMatched } from "../../../utils/redux/actions/jobsActions/jobsChangeState";
import { PARTNER_KEY_JOB } from "../../../utils/redux/actions/jobsActions/jobsConstant";
import { checkXenditMinLimit, removeQueryParam } from "../../../utils/redux/actions/commonActions";
import {
	getAllMessages,
	getMessagesWillUnmount,
	sendMessageWithNewService,
	chatOnAttachFileWithNewService,
	chatOnRecordStopWithNewService,
	acceptSendMk,
	rejecttSendMk,
	callToNumber, toggleTransfer3DsBottomSheet, confirmTransferInApp,
} from "../../../utils/redux/actions/chatActions";
import {
	chatWillUnmount,
	confirmSendMk,
	refuseSendMk,
	toggleTransferMkNote,
	toggleCopyCallBottomSheet,
} from "../../../utils/redux/actions/chatActions/chatChangeState";
import {
	voipIsSupported,
	productModeUnmount,
	setPriceWithAplliedPromoCode,
	bookingSetCurrentPrice, togglePaymentMethodsBottomSheet, toggleTopUpBottomSheet,
} from "../../../utils/redux/actions/bookingActions/bookingChangeState";
import * as LOG_EVENT from "../../../utils/analytics";
import { MessageList } from "../../chat-elements";

import JobChatHistoryHeader from "./JobChatHistoryHeader/JobChatHistoryHeader";
import JobChatHistoryAction from "./JobChatHistoryAction/JobChatHistoryAction";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import BottomSheet from "../../ui-elements/BottomSheet/BottomSheet";
import JobRating from "../../JobRating/JobRating";

import { isChatActive } from "../../../utils/functions/chat-active-by-state";
import PhotoShowMessage from "../../chat-elements/PhotoMessage/PhotoShowMessage";
import { mediaRecorder } from "../../../utils/functions/media-recorder";
import { isIOS, isChrome, isSamsungBrowser } from "../../../utils/functions/check-browser";
import ChatSkeleton from "../../ui-elements/Skeleton/ChatSkeleton";
import { getBrowserInfo } from "../../../utils/functions/getBrowserInfo";
import { TransferConfirmation } from "./TransferConfirmation/TransferConfirmation";
import {
	billingXenditTopUpMyKoin,
	fetchMethodsOfPayment,
	fetchXenditAndBalance,
} from "../../../utils/redux/actions/billingActions";
import {
	getPrimaryPayment,
	storeBookingUrl,
	storePathName, xenditMinLimitBottomSheet,
} from "../../../utils/redux/actions/commonActions/commonChangeState";
import { formatNumbersWithCommas } from "../../../utils/functions/formatNumbersWithCommas";
import { NotifNotSupport } from "../../NotifNotSupport/NotifNotSupport";
import { CustomizedSnackbar } from "../../CustomizedSnackbar/CustomizedSnackbar";
import {
	getNotifNotSupportLocalStorage, getTransferInAppPayload, localStorageSetCurrentBalance,
	setNotifNotSupportLocalStorage, setReviewBookingLocalStorage,
	setStoredUrlLocalStorage,
} from "../../../utils/functions/local-storage";
import { acceptXenditSendMk } from "../../../utils/redux/actions/chatActions/actions/chat-send-request-mk";
import BookingPaymentBottomSheet from "../../Booking/BookingPayment/BookingPaymentBottomSheet";
import TopUpBottomSheet from "../../TopUp/TopUpBottomSheet";
import { topUpMyKoin } from "../../../utils/redux/actions/bookingActions";
import { XenditMinLimitBottomSheet } from "../../xenditMinLimitBottomSheet/XenditMinLimitBottomSheet";
import * as DISPATCH_STATE from "../../../utils/redux/actions/chatActions/chatChangeState";
import { changeStatusOfMessageToCancelled } from "../../../utils/redux/actions/chatActions/actions/chat-connecting";

const AUDIO_RECORDER_FORMAT = "m4a";

class JobChatHistory extends Component {
	inputFileRef = React.createRef(); // for reffering to input file for selecting file
	inputCameraRef = React.createRef(); // for reffering to input camera for selecting file

	constructor(props) {
		super(props);
		this.state = {
			record: false, // when recording start this state being true
			timeout: null,
			inputValue: "",
			thumbsUpSendAction: false,
			inputStyle: null,
			inputRef: null,
			minHeight: 28,
			currentAudioRecorder: null, // for save instance of current audio recorder,
			rec: null,
			timer: null,
			recordingTimout: null,
			audioContext: null,
			isDisabledSendRecodBtn: true,
			isBackButtonClicked: false,
			innerWidth: null,
			innerHeight: null,
			notifNotSupportToggle: true
		};
		this.loadRef = this.loadRef.bind(this);
		this.thumbUpSend = this.thumbUpSend.bind(this);
	}

	loadRef(ref) {
		this.mlistRef = ref;
		if (this.props.cmpRef instanceof Function) this.props.cmpRef(ref);
	}

	componentDidMount() {
		const channelId = this.props.match ? this.props.match.params.id : this.props.job.id;
		this.setState(() => ({
			innerWidth: window.innerWidth,
			innerHeight: window.innerHeight,
		}));
		let path = window.location.pathname;
		if (!_.isNull(this.props.sound)) {
			this.props.sound.stop();
			this.props.dispatch(clearTrackMatched());
		}
		this.props.dispatch(bookingSetCurrentPrice(0));
		if (!_.isNull(this.props.booking.priceWithAppliedCode)) this.props.dispatch(setPriceWithAplliedPromoCode(null));
		if (path.indexOf("chat") > -1) {
			this.props.dispatch(fetchActiveJob(channelId)).then((res) => {
				if (res) {
					this.props.dispatch(getAllMessages(res.id));
					this.props.dispatch(intervalJobStateChecker());
					//check voip is supported or not
					if (!_.isEmpty(res.product.mode) && (res.product.mode.includes("VIDEO_CHAT") || res.product.mode.includes("VOICE_CHAT"))) {
						const mode = [];
						if (res.product.mode.includes("VIDEO_CHAT") && res.product.mode.includes("VOICE_CHAT")) {
							mode.push("VIDEO_CHAT", "VOICE_CHAT");
						} else if (res.product.mode.includes("VIDEO_CHAT")) {
							mode.push("VIDEO_CHAT");
						} else {
							mode.push("VOICE_CHAT");
						}
						this.props.dispatch(voipIsSupported(!_.isEmpty(mode) ? mode : this.props.product.mode));
					}
				}
			}).catch(error => {
				this.props.dispatch(push("/jobs"))
			})
		} else {
			this.props.dispatch(getAllMessages(channelId));
		}
		let AudioContext = window.AudioContext || window.webkitAudioContext || false;
		let audioContext = new AudioContext();
		this.setState({ audioContext });
		const params = parse(window.location.search);
		if (_.has(params, "fromNotif")) {
			LOG_EVENT.logEvent(LOG_EVENT.LAUNCH_FROM_NOTIFICATION_CWA);
			this.props.dispatch(removeQueryParam("fromNotif", this.props.history));
		}

		if (_.has(params, "retryWithTopUp") || _.has(params, "retryWithCard")) {
			this.onConfirmTransferInApp();
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.chat.newMessages?.[0]?.items?.length !== this.props.chat.newMessages?.[0]?.items?.length) {
			if (nextProps.chat.newMessages?.[0]?.items?.[nextProps.chat.newMessages?.[0]?.items?.length-1]?.authorType === "SYSTEM" && nextProps.chat.newMessages?.[0]?.items?.[nextProps.chat.newMessages?.[0]?.items?.length-1]?.state === "CANCELLED") {
				this.props.dispatch(changeStatusOfMessageToCancelled())
			}
		}
		return true
	}

	componentWillUnmount() {
		this.props.dispatch(jobChatHistoryWillUnMount());
		this.props.dispatch(chatWillUnmount());
		this.props.dispatch(getMessagesWillUnmount());
		this.props.dispatch(productModeUnmount());
	}

	/**
	 * When user click on record mic
	 */
	inputRef = (ref) => {
		if (!_.isNull(ref) && _.isNull(this.state.inputRef)) {
			this.setState({ inputRef: ref });
		}
	};

	/**
	 * When user click on selecting one file
	 */
	inputFileChange = (e) => {
		this.props.dispatch(chatOnAttachFileWithNewService(this.inputFileRef.current.files[0]));
	};

	/**
	 * When user click on attach button we click via ref on inputFileRef
	 */
	onAttach = (e) => {
		this.inputFileRef.current.click();
	};

	/**
	 * When user click on camera button we click via ref on inputCameraRef
	 */
	onCamera = (e) => {
		this.inputCameraRef.current.click();
	};

	/**
	 * When user click on camera and get result from camera
	 */
	inputFileCameraChange = (e) => {
		this.props.dispatch(chatOnAttachFileWithNewService(this.inputCameraRef.current.files[0]));
	};

	/**
	 * When user click on record mic
	 */
	recording = () => {
		if (isIOS() && isChrome()) {
			alert("This browser is not suitable for media support. For having best experience please use Safari on your phone.");
		} else {
			if (!this.state.record) {
				if (_.isNull(this.state.recordingTimout)) {
					this.startRecording();
					this.setState({
						recordingTimout: setTimeout(() => {
							clearTimeout(this.state.recordingTimout);
							this.setState({ recordingTimout: null });
						}, 1500),
					});
				}
			} else {
				this.stopRecording();
			}
		}
	};

	startRecording = () => {
		let setTimeoutMessage = setTimeout(() => {
			this.setState(() => ({
				isDisabledSendRecodBtn: false,
			}));
			clearTimeout(setTimeoutMessage);
		}, 1000);
		(async () => {
			try {
				let media = await mediaRecorder(this.state.audioContext);
				let timer = new Timer();
				this.setState((prevState, props) => ({
					record: !prevState.record,
					currentAudioRecorder: media,
					timer,
				}));
				media.start();
				timer.start({ precision: "secondTenths" });
				timer.addEventListener("secondTenthsUpdated", function (e) {
					$("#job-chat-history-timer .values").html(timer.getTimeValues().toString(["minutes", "seconds"]));
				});
			} catch (e) {
				if (isIOS()) {
					alert("This browser is not suitable for media support. For having best experience please use Safari on your phone.");
				} else {
					alert("Your browser does not support audio recording");
				}
				this.onCancelRecording();
			}
		})();
	};

	stopRecording = () => {
		this.setState(() => ({
			isDisabledSendRecodBtn: true,
		}));
		$("#job-chat-history-timer .values").text("");
		this.state.timer.stop();
		this.state.currentAudioRecorder.stop((blob) => {
			let URL = window.URL || window.webkitURL;
			let file = {
				blob: blob,
				blobURL: URL.createObjectURL(blob),
				format: AUDIO_RECORDER_FORMAT,
				name: new Date().toISOString(),
			};
			this.props.dispatch(chatOnRecordStopWithNewService(file));
		});
		this.setState((prevState, props) => ({ record: !prevState.record, currentAudioRecorder: null, timer: null }));
	};

	onCancelRecording = () => {
		$("#job-chat-history-timer .values").text("");

		if (!_.isNull(this.state.timer)) {
			this.state.timer.stop();
			this.setState((prevState, props) => ({ record: false, currentAudioRecorder: null, timer: null }));
		} else {
			this.setState((prevState, props) => ({ record: false, currentAudioRecorder: null, timer: null }));
		}
		this.setState(() => ({
			isDisabledSendRecodBtn: true,
		}));
	};

	/**
	 * when user change input text and write something this function runs
	 */
	onChangeInput = (e) => {
		if (!_.isEmpty(e.target.value.trim())) {
			this.setState({ inputValue: e.target.value });
		} else {
			this.setState({ inputValue: "" });
		}
	};

	onClearInput = (e) => {
		this.setState({ inputValue: "" });
	};

	thumbUpSend() {
		const thumbUpSend = "  👍🏻";
		this.props.dispatch(sendMessageWithNewService(thumbUpSend));
	}

	onSend = (e) => {
		this.setState(() => ({
			isDisabledSendRecodBtn: true,
			inputValue: "",
		}));
		if (parseInt(this.state.inputRef.style.height) > this.state.minHeight) {
			this.state.inputRef.style.height = this.state.minHeight + "px";
		}
		this.props.dispatch(sendMessageWithNewService(this.state.inputValue));
	};

	onAcceptSendMk = () => {
		const sendFormData = {
			browserInfo: getBrowserInfo(),
			channel: "Web",
			shopperRedirectUrl: `${window.location.origin}/jobs/${this.props.job.id}/chat`
		}
		this.props.dispatch(acceptSendMk(undefined, sendFormData))
	}

	// onAcceptSendMk = () => {
	// 	if (this.props.common?.primaryPayment?.[0].type) {
	// 		this.props.dispatch(checkXenditMinLimit("PHP",
	// 			this.props.chat.transferRequested)).then((isLowerThanMinLimit) => {
	// 			if (isLowerThanMinLimit) {
	// 				this.props.dispatch(DISPATCH_STATE.confirmSendMk(null, this.props.chat.transferMkId))
	// 				this.props.dispatch(xenditMinLimitBottomSheet(true));
	// 			} else {
	// 				this.props.dispatch(acceptXenditSendMk(undefined))
	// 			}
	// 		})
	// 	} else {
	// 		this.props.dispatch(acceptXenditSendMk(undefined))
	// 	}
	// }

	// completePaymentMethod = () => {
	// 	this.props.dispatch(togglePaymentMethodsBottomSheet());
	// 	this.props.dispatch(storePathName(`/jobs/${this.props.job.id}/chat?retryWithCard`))
	// 	this.props.dispatch(push("/account/billing/add"));
	// };
	//
	// setTopUpValue = () => {
	// 	this.props.dispatch(acceptXenditSendMk(undefined))
	// };
	//
	// onConfirmTransferInApp = () => {
	// 	this.props.dispatch(fetchXenditAndBalance())
	// 		.then((res) => {
	// 			if (res.data.data.getXenditPaymentMethods.totalItems > 0) {
	// 				const primaryPayment = res.data.data.getXenditPaymentMethods.items.filter((payment) => payment.isPrimary === true);
	// 				this.props.dispatch(getPrimaryPayment(primaryPayment));
	// 			}
	// 		})
	// 	const transferInAppPayload = getTransferInAppPayload();
	// 	this.props.dispatch(confirmTransferInApp({ ...transferInAppPayload }))
	// }

	render() {
		const bottomSheetStyle = {
			zIndex: "100",
			borderRadius: "20px",
			bottom: "0",
			position: "fixed",
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: "414px",
		};

		return _.isEmpty(this.props.chat.newMessages) || _.isEmpty(this.props.job) || !_.has(this.props.job, PARTNER_KEY_JOB) ? (
			<div className="chat-skeleton">
				<ChatSkeleton
					devicewidth={_.isNull(this.state.innerWidth) ? 372 : this.state.innerWidth > 500 ? 412 : this.state.innerWidth - 10}
					deviceheight={_.isNull(this.state.innerHeight) ? 660 : this.state.innerHeight - 65}
				/>
			</div>
		) : (
			<div className="Job-chat-history">
				{window.location.pathname.indexOf("chat") > -1 ? <JobChatHistoryHeader /> : null}
				<div
					className={`job-chat-history-body ${this.props.job.state === 'Queued' && 'margin-top'}`}
					style={{marginBottom: `${((!isChrome() && !isSamsungBrowser()) || isIOS()) && "22px"}`}}
				>
					<MessageList
						refs={this.loadRef}
						className="message-list"
						onPrevChat={this.onPrevChat}
						onDownload={this.onDownload}
						partner={this.props.job.partner}
						dataSource={this.props.chat.newMessages}
						isChatHistory={this.props.isChatHistory}
					/>
				</div>
				{/*{((!isChrome() && !isSamsungBrowser()) || isIOS()) &&*/}
				{/*	this.props.job.state !== "Completed" && <CustomizedSnackbar styles={{bottom: "63px", zIndex: 1}} />}*/}
				{isChatActive(this.props.job.state) ? (
					<JobChatHistoryAction
						onChangeInput={this.onChangeInput}
						onClearInput={this.onClearInput}
						record={this.state.record}
						emoji={this.state.emoji}
						onEmoji={this.onEmoji}
						recording={this.recording}
						stopRecording={this.stopRecording}
						onCancelRecording={this.onCancelRecording}
						inputRef={this.inputRef}
						addEmoji={this.addEmoji}
						inputValue={this.state.inputValue}
						onSend={this.onSend}
						inputFileRef={this.inputFileRef}
						inputCameraRef={this.inputCameraRef}
						inputFileChange={this.inputFileChange}
						inputFileCameraChange={this.inputFileCameraChange}
						thumbsUpSend={this.thumbUpSend}
						onAttach={this.onAttach}
						onCamera={this.onCamera}
						isDisabled={this.state.isDisabledSendRecodBtn}
					/>
				) : null}
				{!_.isEmpty(this.props.client) && (
					<BottomSheet
						classes={this.props.chat.transfer3DsBottomSheet ? null : "confirmation-bottom-sheet"}
						isOpen={this.props.chat.toggleConfirmationSendMkBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.props.dispatch(confirmSendMk(null, null))
								this.props.dispatch(toggleTransfer3DsBottomSheet(false))
							};
						}}
					>
						{this.props.chat.transfer3DsBottomSheet && !this.props.common.xenditMinLimitBottomSheet ? (
								<iframe height="1000" width="100%" id="transfer-inline-frame" name="transfer-inline-frame"> </iframe>
							) :
							(<TransferConfirmation
							primaryButton={
								<CustomButton
									classList="accept-send-mk"
									onClick={this.onAcceptSendMk}
									type="button"
									title={`Pay ${this.props.chat.currencySign}${formatNumbersWithCommas((Number(this.props.chat.transferRequested + this.props.chat.transferFee).toFixed((this.props.chat.transferRequested + this.props.chat.transferFee) % 1 !== 0 ? 2 : 0)) || "")}`}
								/>
							}
						/>)}
					</BottomSheet>
				)}

				<div className="add-new-card-container" id="component-container"></div>

				{!_.isEmpty(this.props.client) && (
					<BottomSheet
						classes="confirmation-bottom-sheet"
						isOpen={this.props.chat.toggleRefuseSendMkBottomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(refuseSendMk(null, null));
						}}
					>
						<div className="bottom-sheet-info">
							<h2>Reject Money Request</h2>
							<p>
								Are you sure to reject the request for {this.props.client.currency.signText}
								{this.props.chat.transferMk}?
							</p>
							<div className="call-to-action">
								<CustomButton title="No, Go Back!" outline variant="outlined" onClick={() => this.props.dispatch(refuseSendMk(null, null))} />
								<CustomButton
									title="Yes, Reject It."
									classList="reject-button"
									onClick={() => this.props.dispatch(rejecttSendMk())}
								/>
							</div>
						</div>
					</BottomSheet>
				)}

				<BottomSheet
					classes="transfer-mk-note-bottom-sheet"
					isOpen={this.props.chat.toggleTransferMkNote}
					styles={bottomSheetStyle}
					config={{ topShadow: false }}
					indicator={true}
					onChangeOverlay={(isOpen) => {
						if (!isOpen) this.props.dispatch(toggleTransferMkNote(null, null));
					}}
				>
					<div className="bottom-sheet-info">
						<p>{this.props.chat.transferMkNote}</p>
						<CustomButton title="Got It!" onClick={() => this.props.dispatch(toggleTransferMkNote(null, null))} />
					</div>
				</BottomSheet>

				{this.props.chat.copyCallottbomSheet ? (
					<BottomSheet
						classes="copy-call-bottom-sheet"
						isOpen={this.props.chat.copyCallottbomSheet}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) this.props.dispatch(toggleCopyCallBottomSheet(null));
						}}
					>
						<div className="bottom-sheet-info">
							<p>{this.props.chat.numberClicked}</p>
							<div className="call-to-actions">
								<CopyToClipboard
									text={this.props.chat.numberClicked}
									onCopy={() => this.props.dispatch(toggleCopyCallBottomSheet(null))}
								>
									<CustomButton title="Copy" outline />
								</CopyToClipboard>
								<CustomButton
									title="Call"
									outline
									variant="outlined"
									onClick={() => {
										this.props.dispatch(callToNumber(this.props.chat.numberClicked));
										this.props.dispatch(toggleCopyCallBottomSheet(null));
									}}
								/>
							</div>
							<CustomButton
								classList="cancel-button"
								title="Cancel"
								onClick={() => this.props.dispatch(toggleCopyCallBottomSheet(null))}
							/>
						</div>
					</BottomSheet>
				) : null}

				{((!isChrome() && !isSamsungBrowser()) || isIOS()) && !getNotifNotSupportLocalStorage() && (
					<BottomSheet
						classes="confirmation-bottom-sheet"
						isOpen={this.state.notifNotSupportToggle}
						styles={bottomSheetStyle}
						config={{ topShadow: false }}
						indicator={true}
						onChangeOverlay={(isOpen) => {
							if (!isOpen) {
								this.setState({ notifNotSupportToggle: false });
								setNotifNotSupportLocalStorage();
							}
						}}
					>
						<NotifNotSupport dismissBottomSheet={() => this.setState({notifNotSupportToggle: false})} />
					</BottomSheet>
				)}

				{/*{this.props.booking.isOpenPaymentMethodsBottomSheet && (*/}
				{/*	<BottomSheet*/}
				{/*		isOpen={this.props.booking.isOpenPaymentMethodsBottomSheet}*/}
				{/*		styles={bottomSheetStyle}*/}
				{/*		classes="payment-method-bottom-sheet"*/}
				{/*		config={{ topShadow: false }}*/}
				{/*		indicator={true}*/}
				{/*		onChangeOverlay={(isOpen) => {*/}
				{/*			if (!isOpen) this.props.dispatch(togglePaymentMethodsBottomSheet());*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		{!_.isNull(this.props.billing.balance) ? (*/}
				{/*			<BookingPaymentBottomSheet*/}
				{/*				onAddMethod={this.completePaymentMethod}*/}
				{/*				paymentMethods={this.props.billing.methods}*/}
				{/*				insufficientBalance={this.props.billing.balance[0].balance <= 0}*/}
				{/*			/>*/}
				{/*		) : null}*/}
				{/*	</BottomSheet>*/}
				{/*)}*/}

				{/*<BottomSheet*/}
				{/*	classes="topup-mykoin-bottom-sheet"*/}
				{/*	isOpen={this.props.booking.isShowTopUpBottomSheet}*/}
				{/*	styles={bottomSheetStyle}*/}
				{/*	config={{ topShadow: false }}*/}
				{/*	indicator={true}*/}
				{/*	onChangeOverlay={(isOpen) => {*/}
				{/*		if (!isOpen) this.props.dispatch(toggleTopUpBottomSheet());*/}
				{/*	}}*/}
				{/*>*/}
				{/*	<TopUpBottomSheet setTopUpValue={this.setTopUpValue} />*/}
				{/*</BottomSheet>*/}

				{/*{this.props.common.xenditMinLimitBottomSheet ? (*/}
				{/*	<BottomSheet*/}
				{/*		isOpen={this.props.common.xenditMinLimitBottomSheet}*/}
				{/*		styles={bottomSheetStyle}*/}
				{/*		config={{ topShadow: false }}*/}
				{/*		indicator={true}*/}
				{/*		onChangeOverlay={(isOpen) => {*/}
				{/*			if (!isOpen) {*/}
				{/*				this.props.dispatch(xenditMinLimitBottomSheet(false));*/}
				{/*				this.props.dispatch(toggleTransfer3DsBottomSheet(false))*/}
				{/*			}*/}
				{/*		}}*/}
				{/*		classes={this.props.chat.transfer3DsBottomSheet ? null : "rejected-payment-bottom-sheet"}*/}
				{/*	>*/}
				{/*		{this.props.chat.transfer3DsBottomSheet ? (*/}
				{/*				<iframe height="1000" width="100%" id="transfer-inline-frame" name="transfer-inline-frame"> </iframe>*/}
				{/*			) :*/}
				{/*			(<XenditMinLimitBottomSheet xenditTopup200Peso={this.setTopUpValue} />)}*/}
				{/*	</BottomSheet>*/}
				{/*) : null}*/}

				{!_.isNull(this.props.unrated) ? <JobRating /> : ""}
				{!_.isNull(this.props.chat.chatShowPhoto) ? <PhotoShowMessage photo={this.props.chat.chatShowPhoto} /> : ""}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	...state.jobs,
	chat: state.chat,
	common: state.common,
	booking: state.booking,
	client: state.common.client,
	billing: state.billing,
	router: state.router
});

export default connect(mapStateToProps)(JobChatHistory);
