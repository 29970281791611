import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { datadogRum } from "@datadog/browser-rum";

import * as DISPATCH_STATE from '../commonChangeState';
import {
	localStorageGetToken,
	localStorageIsTwa,
	locationStorageGetData,
	localStorageGetNotifIsNotSupported,
} from "../../../../functions/local-storage";
import { mapGetcurrentLocationLocalstorage, setGooglePlace, setDefaultLocation } from "../../mapActions";
import { setDefaultPickupLocation, initializeMapShow } from "../../mapActions/mapChangeState";
import { fetchShopInfo } from "../../homeActions";
import { fetchClient } from "./common-client";
import * as LOG_EVENT from '../../../../analytics';
import { appInitFcm } from "./common-fcm";
import { isChrome, isIOS, isSamsungBrowser } from "../../../../functions/check-browser";
import { isSaripay } from "../../../../functions/check-saripay";
import { getSubdomain } from "../../../../functions/get-subdomain";

/**
 * check authority of user
 * @param {*} history 
 */
export function appAuthCheck(client) {
    return (dispatch, getState) => {
        dispatch(DISPATCH_STATE.appAuthCheckBegin());
        if(localStorage.getItem("userWantsToRegister")) {
            localStorage.removeItem("token");
            dispatch(checkIsShop());
        } else {
            let token = localStorageGetToken();
            if (token) {
				dispatch(appInitializeCommon({ token }));
				dispatch(checkClientObject(client));
			} else {
				dispatch(checkIsShop());
			}
        }
    };
}

export const checkClientObject = (client) => {
    return (dispatch, getState) => {
        // client object is not empty 
        // if (client && !_.isNull(client) && _.isEmpty(getState().common.client)) {
        if(client){           
            //store url

            // if (!_.isNull(getState().common.lastPathName)) {
            //     dispatch(push(getState().common.lastPathName));
            // }

            dispatch(resolveClientRequest(client));
        } else {
            dispatch(DISPATCH_STATE.appAuthCheckEnd());
            // check is shopurl
            if(getState().router.location.pathname === "/home") {
                if (getSubdomain(window.location.host) !== "app") {
                    dispatch(fetchShopInfo())
                }
            } else if (getState().router.location.pathname === "/not-support") {
                if ((isSamsungBrowser() && isChrome()) || isIOS()) {
					dispatch(push("/home"));
				}
			}

            dispatch(fetchClient())
                .then((res) => {
                    dispatch(resolveClientRequest(res))
                })
        }
    }
}

export const checkIsShop = () => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const location = locationStorageGetData(getState);
            if (getSubdomain(window.location.host) !== "app") {
                // store
                const pathName = window.location.pathname;
                const { location } = getState().map;
                dispatch(fetchShopInfo()).then((res) => {
                    if (res) {
                        if (_.isNull(location.lat) && _.isNull(location.lng)) dispatch(setDefaultLocation());
                        dispatch(push(pathName));
                        dispatch(DISPATCH_STATE.setLocationSuccessfully());
                        resolve("isStore")
                    }
                });
            } else { 
                if (getState().router.location.pathname === "/not-support") {
					if ((isSamsungBrowser() && isChrome()) || isIOS()) {
						dispatch(push("/home"));
					}
				}
                // not store
                if (!_.isNull(location.lat) || !_.isNull(location.lng)) {
                    // dispatch(push("/home"));

                    dispatch(setDefaultPickupLocation(location));
                    dispatch(DISPATCH_STATE.setLocationSuccessfully());
                    resolve("isNotStore");
                } else {
                    dispatch(initializeMapShow());
                    dispatch(setGooglePlace({}));
                    dispatch(DISPATCH_STATE.navigateToSetLocation());
                    // dispatch(push("/welcome"));
                    resolve("isNotStore");
                }
            }
        })
    }
}

export const resolveClientRequest = (client) => {
    return dispatch => {
        dispatch(configSentryScope(client));
        dispatch(configDataDogUserInfo(client));
        dispatch(DISPATCH_STATE.appAuthGetClient(client));
        if (!localStorageGetNotifIsNotSupported()) {
            if (!localStorageIsTwa()) {
				dispatch(appInitFcm());
			}
        }
        dispatch(DISPATCH_STATE.appAuthCheckInit());
        dispatch(DISPATCH_STATE.appAuthCheckEnd());
        LOG_EVENT.identifyClientInSegment(client);
    }
}

export const appInitializeCommon = (data) => {
    return (dispatch) => {
        dispatch(DISPATCH_STATE.appAuthCheckPassed(data.token));
        dispatch(mapGetcurrentLocationLocalstorage());
    }
}
/**
 * set user for sentry
 * @param {*} user 
 */
const configSentryScope = (user) => {
    return (dispatch) => {
        Sentry.configureScope((scope) => {
            scope.setUser({
                id: user.id,
                email: user.emailAddress,
                username: user.mobileNumber.fullNumber
            });
        });
    }
}
/**
 * set user for Data Dog
 * @param {*} user 
 */
const configDataDogUserInfo = (user) => {
    return (dispatch) => {
        datadogRum.setUser({
            id: user.id,
            mobileNumber: user.mobileNumber.fullNumber,
            email: user.emailAddress,
            name: user.givenName+ ' ' + user.lastName
        })
    }
}