import React, { Component } from "react";
import { connect } from "react-redux";

import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import {ReactComponent as ChatSupport} from "../../../v5-icons/chat-support.svg";
import CancelBookingImage from "../../../v5-icons/cancel-booking.png";
import { toggleCancelBookingBottomSheet } from "../../../utils/redux/actions/bookingActions/bookingChangeState";
class CancelBookingBottomSheet extends Component {
	
	render() {
		return (
			<div className="cancel-booking-bottom-sheet">
				<img src={CancelBookingImage} alt="CancelBookingImage" />
				<h2>Are you sure you want to cancel?</h2>
				<p>saripay team is always here to help you</p>

				<div className="cancel-booking-bottom-sheet-buttons">
					<CustomButton title="Confirm Cancel" classList="cancel-booking-button" onClick={this.props.confirmCancelBooking} />
					<CustomButton title="Back" classList="back" onClick={() => this.props.dispatch(toggleCancelBookingBottomSheet())} />
				</div>

				<div className="chat-with-support" onClick={this.props.navigateToSupport}>
					<ChatSupport />
					<span>Get help</span>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	...state.booking,
});

export default connect(mapStateToProps)(CancelBookingBottomSheet);
